
























import { Roles } from "@/models/user/roles";
import { User } from "@/models/user/user";
import { GenericError } from "@/services/error_service";
import { Vue, Component, Prop } from "vue-property-decorator";
import AuthService from "@/services/auth_service";

@Component
export default class LoggedIn extends Vue {
  @Prop() props!: any;
  protected Roles = Roles;
  protected authService = new AuthService();
  protected user: User = {} as User;

  async created() {
    if (!this.props.user) {
      try {
        const user: any = await this.authService.checkAuth();
        this.user = user;
      } catch (err) {
        this.$router.push("/");
      }
    } else {
      this.user = this.props.user;
    }
  }

  protected async logout(): Promise<void> {
    try {
      await this.authService.logout();
      this.$router.push("/");
    } catch (err) {
      GenericError.popup(err.message);
    }
  }
}
